import React, { useState, useCallback, useContext, useEffect } from 'react'
import styled, { ThemeContext, DefaultTheme } from 'styled-components'
import Dropzone, { DropzoneOptions } from 'react-dropzone'
import { InputConfig } from 'components/input/input'
import { borderColor, InputLabel, InputError } from 'components/input-text/input-text'
import { SvgPlusSign } from 'components/svgs/svg-plus-sign'
import { rgbaWithHex, resetButtonStyles, resetButtonIconStyles, transition } from 'theme/utils'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'
import { formatMapper, bytesToSize } from 'utils/files'

type InputFileProps = DropzoneOptions & {
	config: InputConfig
	hasError?: boolean
	errorMessage?: string
	clearFiles?: boolean
	handleFileDrop?: (name: string, files: File[]) => void
	onReset?: (name: string) => void
	acceptedFiles?: string | string[]
}

const fileText = (size = 0, format: string | string[] = []) => {
	return {
		maxSize: {
			[Language.Pt]: `O ficheiro é demasiado grande. Por favor submeta um ficheiro com menos de ${bytesToSize(size)}.`,
			[Language.En]: `The file is too large. Please submit a file less than ${bytesToSize(size)}.`,
		},
		invalidFile: {
			[Language.Pt]: `Por favor submeta um ficheiro em formato ${formatMapper(format)}.`,
			[Language.En]: `Please submit a file in ${formatMapper(format)} format.`,
		},
	}
}

const InputFileWrapper = styled.div`
	position: relative;
	display: block;
	width: 100%;
	height: 32px;
	overflow: hidden;
	transition: ${transition('box-shadow')};

	&:hover {
		box-shadow: 0 1px 4px 0 rgb(0 0 0 / 12%);
	}
`

const InputFileLabel = styled.span`
	position: absolute;
	top: calc(50% + 2px);
	left: 0;
	width: 100%;
	padding: 0 39px 0 11px;
	overflow: hidden;
	font-weight: 500;
	color: ${props => props.theme.text.primary};
	text-overflow: ellipsis;
	white-space: nowrap;
	transform: translateY(-50%);
`

const InputFileDropzone = styled.div<{ $hasError: boolean; disabled?: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
	border-color: ${props => borderColor(props, props.$hasError, props.disabled)};
	border-style: solid;
	border-width: 1px;
	${props => props.theme.isDark && rgbaWithHex(props.theme.text.primary, 0.2, 'background-color')}
	border-radius: 2px;
	transition: ${transition('border')};

	&:focus {
		border-color: ${props => props.theme.color.primary};
		outline: none;
	}

	${InputFileLabel} {
		color: ${props => props.disabled && props.theme.colors.veryLightPinkThree};
	}
`

const InputFileButton = styled.button<{ hasFiles: boolean }>`
	${resetButtonIconStyles}
	position: absolute;
	top: 50%;
	right: 11px;
	pointer-events: ${props => (props.hasFiles ? 'auto' : 'none')};
	transform: translateY(-50%);

	&:hover,
	&:focus {
		${resetButtonStyles}
	}
`

export const InputFile = ({
	config,
	acceptedFiles,
	accept,
	multiple,
	maxSize,
	hasError,
	errorMessage,
	clearFiles,
	handleFileDrop,
	onReset,
}: InputFileProps) => {
	const themeContext = useContext(ThemeContext) as DefaultTheme
	const { language } = useLocationContext()
	const [inputErrorMessage, setInputErrorMessage] = useState('')
	const [inputHasError, setInputHasError] = useState(false)
	const [myFiles, setMyFiles] = useState<File[]>([])
	const hasFiles = myFiles.length > 0

	const removeAll = useCallback(() => {
		setMyFiles([])
		setInputErrorMessage('')
		setInputHasError(false)
		onReset && onReset(config.name)
	}, [config, onReset])

	useEffect(() => {
		clearFiles && removeAll()
	}, [clearFiles, removeAll])

	const onDropAccepted = useCallback(
		(files: File[]) => {
			setInputHasError(false)
			setInputErrorMessage('')
			setMyFiles([...files])

			handleFileDrop && handleFileDrop(config.name, [...files])
		},
		[config.name, handleFileDrop]
	)

	const onDropRejected = useCallback(
		(files: any) => {
			const isFileTooLarge = maxSize ? files.length > 0 && files[0].size > maxSize : false
			setMyFiles([])
			setInputHasError(true)
			setInputErrorMessage(fileText(maxSize, acceptedFiles).invalidFile[language])
			isFileTooLarge && setInputErrorMessage(fileText(maxSize, acceptedFiles).maxSize[language])
			handleFileDrop && handleFileDrop(config.name, [])
		},
		[acceptedFiles, language, maxSize, config.name, handleFileDrop]
	)

	const handleButtonClick = () => {
		// * In the future we may add here some logic to
		// * opening the file dialog programmatically.
		hasFiles && removeAll()
	}

	return (
		<>
			{config && config.label && (
				<InputLabel {...(config.id && { htmlFor: config.id })} disabled={config.disabled}>
					{config.label}
				</InputLabel>
			)}
			<InputFileWrapper>
				<Dropzone
					accept={accept}
					multiple={multiple}
					maxSize={maxSize}
					onDropAccepted={onDropAccepted}
					onDropRejected={onDropRejected}>
					{({ getRootProps, getInputProps }) => {
						return (
							<InputFileDropzone {...getRootProps()} $hasError={hasError || inputHasError} disabled={config.disabled}>
								<input id={config.id} name={config.name} {...getInputProps()} />
								<InputFileLabel>{myFiles.map(file => file.name).join(', ')}</InputFileLabel>
							</InputFileDropzone>
						)
					}}
				</Dropzone>
				<InputFileButton
					type="button"
					hasFiles={hasFiles}
					{...(!hasFiles && { tabIndex: -1 })}
					onClick={handleButtonClick}>
					<SvgPlusSign
						suffix={`-${config.name}`}
						rotate={hasFiles ? 45 : 0}
						fill={hasError || inputHasError ? themeContext.colors.dullRed : themeContext.colors.twilightBlue}
					/>
				</InputFileButton>
			</InputFileWrapper>
			{(errorMessage || inputErrorMessage) && <InputError>{errorMessage || inputErrorMessage}</InputError>}
		</>
	)
}
